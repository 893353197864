import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { enhanceStrapiData } from '@/utils/enhanceStrapiData'
import { getSiteData } from '@/utils/siteData'
import { PageBackgroundColors } from '@/constants/enums'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import SEO from '@/components/seo'

const Post = ({ data, pageContext }) => {
  const { posts, insights, releaseNotes, site, downloads, downloadCategories } =
    data
  const { seo, title, sections, cover, backgroundColor, hideHero } =
    enhanceStrapiData(data.strapiContent) || {}
  const global = {
    posts: posts ? posts.nodes : [],
    insights: insights ? insights.nodes : [],
    releaseNotes: releaseNotes ? releaseNotes.nodes : [],
    downloads: downloads ? downloads.nodes : [],
    downloadCategories: downloadCategories ? downloadCategories.nodes : [],
  }

  const heroProps = {
    strapi_component: 'sections.hero-compact',
    image: cover,
    space: 'none',
    disableTopMargin: true,
  }

  const finalSections = [hideHero ? undefined : heroProps, ...sections].filter(
    Boolean,
  )

  return (
    <>
      <SEO
        title={title}
        seo={seo}
        site={site.nodes[0]}
        pageContext={pageContext}
      />
      <Layout
        global={getSiteData(data)}
        pageContext={pageContext}
        backgroundColor={backgroundColor || PageBackgroundColors.white}
      >
        <Sections as="main" sections={finalSections} global={global} />
      </Layout>
    </>
  )
}

Post.defaultProps = {}

Post.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Post

export const query = graphql`
  query PostQuery(
    $id: String!
    $locale: String!
    $formatString: String = "DD.MM.YYYY"
  ) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: allStrapiSite(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Site
      }
    }
    header: allStrapiHeader(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Header
      }
    }
    footer: allStrapiFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Footer
      }
    }
    strapiContent: strapiPost(id: { eq: $id }) {
      route
      title
      seo {
        ...SharedSeo
      }
      datetime(formatString: $formatString, locale: $locale)
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        height
        alternativeText
        caption
        width
        url
      }
      backgroundColor
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_BADGE_DIVIDER {
          ...SectionsBadgeDivider
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS {
          ...SectionsColumns
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_IMAGE {
          ...SectionsColumnsHeadingImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_TEXT {
          ...SectionsColumnsHeadingText
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_VIDEO {
          ...SectionsColumnsHeadingVideo
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_IMAGE {
          ...SectionsColumnsHighlightImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_TEXT {
          ...SectionsColumnsHighlightText
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_VIDEO {
          ...SectionsColumnsHighlightVideo
        }
        ... on STRAPI__COMPONENT_SECTIONS_FEATURE_LIST {
          ...SectionsFeatureList
        }
        ... on STRAPI__COMPONENT_SECTIONS_FEATURE_DESCRIPTION {
          ...SectionsFeatureDescription
        }
        ... on STRAPI__COMPONENT_SECTIONS_HEADING_IMAGE {
          ...SectionsHeadingImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_HEADING_SECTION {
          ...SectionsHeadingSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_FIGURE {
          ...SectionsHighlightFigure
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_LOGO {
          ...SectionsHighlightLogo
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_PLATFORM {
          ...SectionsHighlightPlatform
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_STATS {
          ...SectionsHighlightStats
        }
        ... on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_TEXT {
          ...SectionsHighlightText
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_COLLECTION {
          ...SectionsTeaserCollection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_IMAGE {
          ...SectionsTeaserImage
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEASER_LOGO {
          ...SectionsTeaserLogo
        }
        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_SECTION {
          ...SectionsTestimonialSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_SECTION {
          ...SectionsTextSection
        }
      }
      hideHero
    }
    downloads: allStrapiDownload(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Download
      }
    }
    downloadCategories: allStrapiDownloadCategory(
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        ...DownloadCategory
      }
    }
  }
`
